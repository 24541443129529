.navigation-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.navigation-arrow.up {
  transform: rotate(180deg);
}

.navigation-arrow.left {
  transform: rotate(90deg);
}

.navigation-arrow.right {
  transform: rotate(-90deg);
}
