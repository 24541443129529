.active {
  background-color: #124559;
  color: #fff;
}

.btn {
  margin: 0 2px;
  padding: 6px 12px;
  cursor: pointer;
}

.global-container {
  margin: 0 auto;
  display: flex;
  max-width: 1670px;
  user-select: none;
}

.global-wrapper {
  overflow: hidden;
}

.calendar-summary {
  padding: 0 12px 12px;
}

.calendar-summary h3 {
  margin: 9px 0 15px;
}

.submit {
  margin: 12px 0 0;
  display: inline-block;
  border: none;
  height: 40px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 15px;
}
