.navigation-months {
  display: flex;
  align-items: center;

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__item {
    margin: 0 2px;
    padding: 6px 12px;
    cursor: pointer;
  }
}
