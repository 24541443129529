.loader {
  position: absolute;
  top: 0;
  left: 154px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  z-index: 9;

  img {
    margin-top: 300px;
    width: 75px;
    height: 75px;
  }
}
