.navigation {
  &__container {
    padding: 0 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    gap: 16px;

    &.isFetching {
      .navigation-arrow,
      .navigation__month,
      .btn {
        pointer-events: none;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px 12px;
  }

  @media (max-width: 768px) {
    &__today {
      display: none;
    }
  }
}
