.navigation {
  &__yearChanger {
    display: flex;
    align-items: center;
  }

  &__year {
    margin: 0 2px;
    padding: 6px 12px;
    cursor: pointer;
  }
}
