.body {
  &__container {
    position: relative;
    margin-top: 40px;
    padding: 0 12px 4px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}
