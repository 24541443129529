.legend {
  &__wrapper {
    margin-left: auto;
    display: flex;
    gap: 16px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__symbol {
    width: 10px;
    height: 10px;

    &--reserved {
      background-color: #e13e55;
    }

    &--on-hold {
      background-color: #fccc48;
    }
  }
}
