.body {
  &__header {
    display: flex;
    min-width: 1175px;
  }

  &__labelDate {
    margin: 4px 0;
    padding: 5px 0;
    text-align: center;
    line-height: 20px;
    min-width: 150px;
    width: 150px;
  }

  &__labelList {
    margin: 0 0 0 4px;
    padding: 0;
    display: flex;
    list-style: none;
    border-bottom: 1px solid #e0e0e0;
    overflow: hidden;
  }

  &__singleLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    width: 48px;
    height: 48px;
  }
}
